import React from 'react';
import { SEO } from '../components/seo';
import { Layout } from '../components/layout';

function NotFoundPage() {
    return (
        <Layout>
            <SEO title="404: Not Found" />
            <main>
                <h1>Not Found</h1>
                <p>
                    You just hit a route that doesn&#39;t exist... the sadness.
                </p>
            </main>
        </Layout>
    );
}

export default NotFoundPage;
